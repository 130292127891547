<template>
  <div>
    <!--    <Main>-->
    <!--      <a-row justify="center" :gutter="25">-->
    <!--        <a-col :xxl="8" :lg="24" :xs="24">-->
    <!--          <Suspense>-->
    <!--            <template #default>-->
    <!--              <Overview />-->
    <!--            </template>-->
    <!--            <template #fallback>-->
    <!--              <cds-cards headless>-->
    <!--                <a-skeleton active />-->
    <!--              </cds-cards>-->
    <!--            </template>-->
    <!--          </Suspense>-->
    <!--        </a-col>-->
    <!--        <a-col :xxl="16" :xs="24">-->
    <!--          <Suspense>-->
    <!--            <template #default>-->
    <!--              <LineChartOverview />-->
    <!--            </template>-->
    <!--            <template #fallback>-->
    <!--              <cds-cards headless>-->
    <!--                <a-skeleton active />-->
    <!--              </cds-cards>-->
    <!--            </template>-->
    <!--          </Suspense>-->
    <!--        </a-col>-->
    <!--        <a-col :xxl="8" :xs="24">-->
    <!--          <Suspense>-->
    <!--            <template #default>-->
    <!--              <InteractiveOverview />-->
    <!--            </template>-->
    <!--            <template #fallback>-->
    <!--              <cds-cards headless>-->
    <!--                <a-skeleton active />-->
    <!--              </cds-cards>-->
    <!--            </template>-->
    <!--          </Suspense>-->
    <!--        </a-col>-->
    <!--        <a-col :xxl="16" :xs="24">-->
    <!--          <Suspense>-->
    <!--            <template #default>-->
    <!--              <Register />-->
    <!--            </template>-->
    <!--            <template #fallback>-->
    <!--              <cds-cards headless>-->
    <!--                <a-skeleton active />-->
    <!--              </cds-cards>-->
    <!--            </template>-->
    <!--          </Suspense>-->
    <!--        </a-col>-->
    <!--      </a-row>-->
    <!--    </Main>-->
  </div>
</template>

<script>
// import { defineAsyncComponent } from 'vue';
// import { Main } from '../styled';
// import { useStore } from 'vuex';
import {  onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {DataService} from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
// const Overview = defineAsyncComponent(() => import('./overview/Overview'));
// const LineChartOverview = defineAsyncComponent(() => import('./overview/LineChartOverview'));
// const Register = defineAsyncComponent(() => import('./overview/Register'));
// const InteractiveOverview = defineAsyncComponent(() => import('./overview/InteractiveOverview'));

export default {
  name: 'SocialMedia',
  components: {
    // Overview,
    // LineChartOverview,
    // Register,
    // InteractiveOverview,
    // Main
  },
  async setup() {
    function getPathOfFirstObject(tree) {
      if (tree && Array.isArray(tree) && tree.length > 0) {
        const firstItem = tree[0];
        if (firstItem.children === null) {
          return firstItem.path;
        } else {
          return getPathOfFirstObject(firstItem.children);
        }
      }
      return '/settings/profile-settings/profile';
    }
    // const { state } = useStore();
    const { push } = useRouter();
    // const menus = computed(() => state.auth.userInfo.menus || []);
    onMounted(async () => {
      const response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO);
      const path = getPathOfFirstObject(response.data.menus);
      if(path) {
        push(path);
      }
    });
  },
};
</script>
